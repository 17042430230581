import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';

const PoliticyContainer = styled.section`
  text-align: center;
  padding: 3rem 0;
  max-width: 1000px;
  margin: 0 auto;
`;

const PoliticySubcontainer = styled.div`
  padding: 0 1rem;
`;

const PoliticyTitle = styled.h2`
  font-weight: 700;
  font-size: 1.3em;
`;
const PoliticySubtitle = styled.h3`
  font-weight: 700;
  font-size: 1.1em;
  text-align: left;
`;
const PoliticyParagraph = styled.p`
  font-weight: 300;
  font-size: 1em;
  text-align: left;
  margin-bottom: 2em;
`;

const Bold = styled.b`
  font-weight: 700;
`;

const PoliticyPage = React.memo(() => (
  <Layout>
    <Seo title="Politica de Privacidad" />
    <PoliticyContainer>
      <PoliticyTitle>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES DE STO COLOMBIA SAS</PoliticyTitle>
      <PoliticyParagraph>
        La presente Política de Tratamiento de Datos Personales (la “<Bold>Política</Bold>”) regula
        la recolección, almacenamiento, uso, circulación y supresión de datos personales que realiza
        STO COLOMBIA SAS (“<Bold>STO</Bold>”), de acuerdo con las disposiciones contenidas en la Ley
        Estatutaria 1581 de 2012, y el Decreto 1074 de 2015, por medio de los cuales se dictan
        disposiciones generales para la protección de datos personales.
      </PoliticyParagraph>
      <PoliticySubtitle>
        1. Información del Responsable del Tratamiento de la información personal
      </PoliticySubtitle>
      <PoliticyParagraph>
        La empresa responsable del tratamiento de los datos personales es:
      </PoliticyParagraph>
      <PoliticyParagraph>
        <ul>
          <li>Razón social: STO COLOMBIA SAS</li>
          <li>Nit.: 900487910 - 2</li>
          <li>Domicilio: Bogotá D.C., Colombia.</li>
          <li>Dirección: carrera 70 # 19-52</li>
          <li>Email: info@stocolombia</li>
        </ul>
      </PoliticyParagraph>
      <PoliticySubtitle>
        2. Tratamiento al cual serán sometidos los datos personales y su finalidad
      </PoliticySubtitle>
      <PoliticyParagraph>
        Los datos personales son recolectados, almacenados, organizados, usados, circulados,
        transmitidos, transferidos, actualizados, rectificados, suprimidos, eliminados y gestionados
        de acuerdo a la naturaleza de los datos y de las finalidades establecidas en esta Política.
      </PoliticyParagraph>
      <PoliticySubcontainer>
        <PoliticySubtitle>
          2.1. Tratamiento de datos personales de naturaleza pública y privada.
        </PoliticySubtitle>
        <PoliticyParagraph>
          STO accede a datos de sus clientes y colaboradores tales como sus nombres, número de
          cédula, correos electrónicos, teléfonos fijos y celulares, entre otros. Estos datos son
          recolectados con las siguientes finalidades:
        </PoliticyParagraph>
        <PoliticySubcontainer>
          <PoliticySubtitle>Finalidades para el tratamiento de datos de clientes:</PoliticySubtitle>
          <PoliticyParagraph>
            <ul>
              <li>
                Cumplimiento de obligaciones comerciales en el marco de relaciones contractuales.
              </li>
              <li>
                Procesar y asegurar el cumplimiento y entrega de los productos y/o servicios
                adquiridos por los clientes de STO, así como elaborar la facturación
                correspondiente.
              </li>
              <li>Envío de publicidad sobre productos y servicios de STO.</li>
              <li>Elaboración de informes de desempeño por las áreas comerciales y de mercadeo.</li>
              <li>Ofrecimiento de productos de las distintas líneas comerciales de STO.</li>
              <li>Comunicación descuentos, promociones y nuevos lanzamientos de productos.</li>
              <li>
                Realización de análisis y perfilamientos de los clientes que permita definir los
                productos que se acomodan a sus gustos y preferencias de compra.
              </li>
              <li>
                Comunicación sobre la realización de actividades y eventos organizados por STO.
              </li>
              <li>
                Desarrollo de investigaciones de mercado y hábitos de consumo, análisis estadísticos
                y reportes de comportamiento de clientes.
              </li>
              <li>Diseño y ofrecimiento programas de lealtad y beneficio para los clientes.</li>
              <li>
                Envío de encuestas de satisfacción o cualquier otro mecanismo para evaluar la
                calidad de los productos y servicios prestados por STO.
              </li>
            </ul>
          </PoliticyParagraph>
          <PoliticySubtitle>
            Finalidades para el tratamiento de datos de trabajadores:{' '}
          </PoliticySubtitle>
          <PoliticyParagraph>
            <ul>
              <li>
                Ejecución de los contratos suscritos con STO y envío de información relacionada con
                la relación contractual.
              </li>
              <li>
                Envío de información a proveedores de servicios tales como agencia de viajes,
                corredores de seguro y cooperativas.
              </li>
              <li>
                Dar cumplimiento a las obligaciones laborales del Empleador, establecidas en la
                legislación laboral, el contrato de trabajo, el Reglamento Interno de Trabajo, tales
                como: afiliación al Sistema de Seguridad Social Integral y pago de aportes,
                afiliación a la Caja de Compensación y pago de aportes, pago a la DIAN de las sumas
                retenidas, emitir certificados de ingresos y retenciones y certificados laborales, y
                suministrar información a una entidad o autoridad nacional que requiera datos
                personales, conforme con la normas vigentes.
              </li>
              <li>
                Envío de publicidad y actividades organizadas por aliados de STO dirigido a
                empleados.
              </li>
              <li>
                Notificación en caso de emergencias durante su estancia en las instalaciones de STO.
              </li>
              <li>
                Identificación plena del trabajador, mediante archivo y manejo de sus datos de
                contacto, información profesional y académica del trabajador, entre otros.
              </li>
              <li>
                De ser el caso, para la contratación de seguros de vida y de gastos médicos o para
                el otorgamiento de cualquier otra prestación que derive de la relación laboral con
                STO.
              </li>
              <li>
                Comunicación en general, registro, capacitaciones, autorizaciones y para la gestión
                de las actividades o actuaciones en las cuales de relacionan los empleados y sus
                familiares con STO.
              </li>
              <li>
                Investigar, verificar y validar la información suministrada por los empleados, con
                cualquier información de la que STO legítimamente disponga.
              </li>
              <li>Adelantar procesos de selección de personal dirigidos por STO.</li>
              <li>
                En caso de que STO no se encuentre en capacidad de realizar el tratamiento por sus
                propios medios, podrá transferir los datos recopilados para que sean tratados por un
                tercero dentro de Colombia, el cual actuará como encargado del tratamiento y deberá
                garantizar condiciones idóneas de confidencialidad y seguridad de la información
                transferida para el tratamiento.
              </li>
              <li>
                Realizar los pagos necesarios en la cuenta bancaria que señale el Trabajador o
                entidades expresamente indicadas por el Trabajador.
              </li>
              <li>
                Contratar seguros de vida y/o gastos médicos o para el otorgamiento de cualquier
                otra prestación que derive de la relación laboral con el Empleador, de ser el caso.
              </li>
              <li>
                Notificar a familiares en caso de emergencias durante el horario de trabajo o con
                ocasión del desarrollo del mismo.
              </li>
              <li>
                Mantener la seguridad y salud de los trabajadores en el lugar de trabajo, de
                conformidad con las normas aplicables al Sistema de Gestión de la Seguridad y Salud
                en el Trabajo (en adelante “SG-SST”) y conservar los documentos indicados en el
                artículo 2.2.4.6.13 del Decreto 1072 de 2015.
              </li>
              <li>Informar instrucciones en ocasión al contrato de trabajo.</li>
              <li>Evaluar el desempeño laboral del Trabajador.</li>
              <li>
                Recolectar información y evidencia con el fin de realizar procesos disciplinarios
                laborales, de ser el caso.
              </li>
              <li>
                Almacenar los datos personales en el sistema informático interno del Empleador.
              </li>
              <li>
                Circular y consultar los datos entre las personas especificadas en esta Sección,
                exclusivamente para los fines aquí previstos.
              </li>
              <li>
                Usar la información para procedimientos y documentos relacionados a la relación
                laboral del Trabajador con el Empleador.
              </li>
              <li>
                Enviar información sobre productos y servicios ofrecidos por el Empleador a sus
                Trabajadores.
              </li>
              <li>
                Permitir el archivo físico de la información en las instalaciones de terceros que
                proveen estos servicios.
              </li>
            </ul>
          </PoliticyParagraph>
          <PoliticySubtitle>
            Finalidades para el tratamiento de datos de proveedores:
          </PoliticySubtitle>
          <PoliticyParagraph>
            <ul>
              <li>
                Organización del registro de información de proveedores para el envio de órdenes de
                compra.
              </li>
              <li>
                Investigación, verificación y validación de la información suministrada por los
                proveedores, con cualquier información de la que STO legítimamente disponga y listas
                internacionales sobre comisión de delitos y lavado de activos.
              </li>
              <li>
                Comunicación, consolidación, organización, actualización, control, acreditación,
                aseguramiento, estadística, reporte, mantenimiento, interacción, y gestión de las
                actuaciones, informaciones y actividades en las cuales se relacionan o vinculan a
                los proveedores y contratistas con STO.
              </li>
              <li>
                {' '}
                Elaboración de reportes sobre desempeño y cumplimiento por parte de los proveedores.
              </li>
            </ul>
          </PoliticyParagraph>
        </PoliticySubcontainer>
        <PoliticySubtitle>2.2. Tratamiento de datos sensibles:</PoliticySubtitle>
        <PoliticyParagraph>
          STO podrá acceder y hacer uso de datos sensibles de sus trabajadores tales como videos,
          fotografías y huellas dactilares. Así mismo, STO obtiene información sobre el estado de
          salud de sus empleados en cumplimiento de obligaciones legales sobre salud ocupacional.
          Para estos efectos, STO dará aplicación a las disposiciones legales sobre tratamiento de
          datos sensibles, incluyendo las siguientes:
        </PoliticyParagraph>
        <PoliticySubcontainer>
          <PoliticyParagraph>
            <ol type="a">
              <li>
                Obtener autorización explícita del Titular para el tratamiento, informando el
                carácter facultativo de la misma y los datos que se consideran sensibles. Esta
                autorización será implementada en toda recolección de datos sensibles, salvo en los
                siguientes casos en los que no se requiere autorización por disposición legal:
              </li>
            </ol>
          </PoliticyParagraph>
          <PoliticySubcontainer>
            <PoliticyParagraph>
              <ol type="a">
                <li>
                  El Tratamiento sea necesario para salvaguardar el interés vital del Titular y este
                  se encuentre física o jurídicamente incapacitado. En estos eventos, los
                  representantes legales deberán otorgar su autorización.
                </li>
                <li>
                  El Tratamiento sea efectuado en el curso de las actividades legítimas y con las
                  debidas garantías por parte de una fundación, ONG, asociación o cualquier otro
                  organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o
                  sindical, siempre que se refieran exclusivamente a sus miembros o a las personas
                  que mantengan contactos regulares por razón de su finalidad. En estos eventos, los
                  datos no se podrán suministrar a terceros sin la autorización del Titular.
                </li>
                <li>
                  El Tratamiento se refiera a datos que sean necesarios para el reconocimiento,
                  ejercicio o defensa de un derecho en un proceso judicial.
                </li>
                <li>
                  El Tratamiento tenga una finalidad histórica, estadística o científica. En este
                  evento deberán adoptarse las medidas conducentes a la supresión de identidad de
                  los Titulares.
                </li>
              </ol>
            </PoliticyParagraph>
          </PoliticySubcontainer>
        </PoliticySubcontainer>
        <PoliticyParagraph>
          En los casos en que STO tenga acceso a datos sensibles, el tratamiento de los mismos se
          realizará con las siguientes finalidades:
        </PoliticyParagraph>
        <PoliticySubcontainer>
          <PoliticySubtitle>
            Datos relativos al estado de salud e historia clínica ocupacional de candidatos y
            trabajadores para los siguientes fines:
          </PoliticySubtitle>
          <PoliticyParagraph>
            <ul>
              <li>
                Verificar si el solicitante cumple con los requisitos físicos necesarios para
                desempeñar el cargo para el cual está aplicando o fue contratado.
              </li>
              <li>
                Contar con la información necesaria para atender cualquier emergencia médica que se
                presente durante la prestación de servicios en las instalaciones de STO.
              </li>
              <li>
                Cumplir con las normas de seguridad y salud en el trabajo e implementar el SG-SST, y
                cualquier otro programa, sistema y/o plan que busque proteger la salud del
                trabajador y las personas en el lugar de trabajo.
              </li>
            </ul>
          </PoliticyParagraph>
          <PoliticySubtitle>Datos biométricos de trabajadores:</PoliticySubtitle>
          <PoliticyParagraph>
            <ul>
              <li>Identificar al personal que acceda a las instalaciones de STO. </li>
              <li>Dar acceso a las instalaciones de STO.</li>
              <li>Verificar la permanencia del trabajador en las instalaciones de STO.</li>
              <li>
                Cumplimiento de las obligaciones legales que se deriven de la relación laboral,
                tales como, realizar todos los trámites necesarios para la inscripción de
                beneficiarios ante el Sistema de Seguridad Social, o cualquier otra actividad
                derivada de la legislación aplicable.
              </li>
              <li>Comunicar al trabajador las actividades de bienestar que STO ha organizado.</li>
              <li>
                Proporcionar la seguridad respectiva en las capacitaciones y actividades que realiza
                STO a sus trabajadores.
              </li>
            </ul>
          </PoliticyParagraph>
        </PoliticySubcontainer>
        <PoliticySubtitle>2.3 Video vigilancia</PoliticySubtitle>
        <PoliticyParagraph>
          STO utiliza diversos medios de video vigilancia instalados en diferentes lugares de sus
          oficinas y tiendas.
          <br />
          La información recolectada se utilizará para fines de seguridad de las personas, los
          bienes e instalaciones de STO. Esta información puede ser empleada como prueba en
          cualquier tipo de proceso ante cualquier tipo de autoridad y organización. STO
          implementará autorizaciones para la obtención de esta información que cumpla con las
          normas que regulan el acceso a datos sensibles.
        </PoliticyParagraph>
        <PoliticySubtitle>
          2.4 Tratamiento de datos de niños, niñas y adolescentes:
        </PoliticySubtitle>
        <PoliticyParagraph>
          STO podrá tratar datos de los hijos de candidatos o trabajadores que sean menores de 18
          años. Esta información es recolectada con la autorización de los padres o de las personas
          facultadas legalmente para ello, bajo los requisitos señalados en la regulación de
          protección de datos. Por esta razón, las finalidades que tiene la recolección de estos
          datos son:
        </PoliticyParagraph>
        <PoliticyParagraph>
          <ul>
            <li>
              Identificación de los hijos de un candidato en las visitas y entrevistas que se
              realicen en el domicilio del candidato.
            </li>
            <li>
              Cumplimiento de las obligaciones legales que se deriven de la relación laboral, tales
              como, realizar todos los trámites necesarios para la inscripción de beneficiarios,
              ante las autoridades, tales como, ante el Sistema de Seguridad Social, o cualquier
              otra actividad derivada de la legislación aplicable.
            </li>
            <li>
              Comunicar a los empleados las actividades de bienestar que STO ha organizado para sus
              hijos.
            </li>
            <li>Comunicar a los trabajadores el nacimiento de los hijos de funcionarios de STO.</li>
          </ul>
        </PoliticyParagraph>
      </PoliticySubcontainer>
      <PoliticySubtitle>3. Transferencias y Transmisiones de datos personales:</PoliticySubtitle>
      <PoliticyParagraph>
        STO puede realizar transferencias y transmisiones de datos personales a Estados Unidos y
        Alemania. Para este fin, STO implementará los mecanismos legalmente dispuestos para el envío
        de información a terceros países.
      </PoliticyParagraph>
      <PoliticySubtitle>4. Derechos que le asisten como Titular de los datos</PoliticySubtitle>
      <PoliticyParagraph>
        De conformidad con lo establecido en el artículo 8 de la Ley 1581 de 2012 y el decreto 1074
        de 2015 (Capítulo 25), el Titular de los datos personales tiene los siguientes derechos:
      </PoliticyParagraph>
      <PoliticyParagraph>
        <ol type="a">
          <li>
            Conocer, actualizar y rectificar sus datos personales frente a STO, en su calidad de
            Responsable del Tratamiento. Este derecho podrá ejercerlo frente a datos parciales,
            inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento
            esté expresamente prohibido o no haya sido autorizado.
          </li>
          <li>
            Solicitar prueba de la autorización otorgada a STO, en su calidad de Responsable del
            Tratamiento, salvo cuando expresamente se exceptúe como requisito para el Tratamiento,
            de conformidad con lo establecido en el artículo 10 de la Ley 1581 de 2012 (o en las
            normas que la reglamenten, adicionen, complementen, modifiquen o deroguen), o cuando se
            haya presentado la continuidad del tratamiento según lo previsto en el numeral 4° del
            artículo 2.2.2.25.2.7 del Decreto 1074 de 2015.
          </li>
          <li>
            Ser informado por STO , previa solicitud, respecto del uso que le ha dado a sus datos
            personales;
          </li>
          <li>
            Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo
            dispuesto en la Ley 1581 de 2012, una vez haya agotado el trámite de consulta o reclamo
            ante STO
          </li>
          <li>
            Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no
            se respeten los principios, derechos y garantías constitucionales y legales. La
            revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio
            haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en
            conductas contrarias a la ley y la Constitución.
          </li>
          <li>
            Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento
          </li>
        </ol>
      </PoliticyParagraph>
      <PoliticySubtitle>
        5. Área responsable de la atención de peticiones, consultas y reclamos
      </PoliticySubtitle>
      <PoliticyParagraph>
        El Área Administrativa de STO, será responsable de la atención de peticiones, consultas,
        reclamos, quejas o para el ejercicio de los derechos del Titular de la información personal
      </PoliticyParagraph>
      <PoliticySubtitle>
        6. Procedimiento para ejercer los derechos del Titular de los datos
      </PoliticySubtitle>
      <PoliticySubcontainer>
        <PoliticySubtitle>6.1. Procedimiento para acceso y de consulta</PoliticySubtitle>
        <PoliticyParagraph>
          El Titular de los datos, o sus causahabientes, podrán consultar la información que repose
          en las bases de datos en posesión de STO, para lo cual deberán comunicar la
          correspondiente petición al correo electrónico info@stocolombia.com o comunicarse al
          (1)7451280. También será posible formular estas peticiones por escrito, y radicarla de
          lunes a viernes en horario 8:00 AM – 12:00 PM y 2:00 PM a 5:30 PM, en la carrera 70 #
          19-52, de la ciudad de Bogotá. Para evitar que terceros no autorizados accedan a la
          información personal del Titular de los datos, será necesario previamente establecer la
          identificación del Titular. Cuando la solicitud sea formulada por persona distinta del
          Titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no
          presentada. La consulta será atendida en un término máximo de diez (10) días hábiles
          contados a partir de la fecha de recibo. Cuando no fuere posible atender la consulta
          dentro de dicho término, se informará al interesado, expresando los motivos de la demora y
          señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar
          los cinco (5) días hábiles siguientes al vencimiento del primer término.
        </PoliticyParagraph>
        <PoliticySubtitle>
          6.2. Procedimiento para solicitar actualización, corrección, supresión, revocatoria de la
          autorización o para presentar reclamos
        </PoliticySubtitle>
        <PoliticyParagraph>
          El Titular, o sus causahabientes, que consideren que la información contenida en la base
          de datos de STO debe ser objeto de corrección, actualización o supresión, o cuando
          adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en esta ley,
          podrán presentar un reclamo ante STO, el cual será tramitado bajo las siguientes reglas,
          de conformidad con el artículo 15 de la ley 1581 de 2012:
        </PoliticyParagraph>
        <PoliticySubcontainer>
          <PoliticyParagraph>
            <ol type="a">
              <li>
                El reclamo se formulará mediante solicitud que puede ser enviada al correo
                info@stocolombia.com o radicando una comunicación por escrito de lunes a viernes en
                horario 8:00 AM – 12:30 PM y 2:00 PM a 5:30 PM, en la Carrera 70 # 19-52 de la
                ciudad de Bogotá.
              </li>
              <li>
                Para evitar que terceros no autorizados accedan a la información personal del
                Titular de los datos, será necesario previamente establecer la identificación del
                Titular. Cuando la solicitud sea formulada por persona distinta del Titular y no se
                acredite que la misma actúa en representación de aquél, se tendrá por no presentada.
              </li>
              <li>
                La solicitud debe contener la siguiente información:
                <ol type="i">
                  <li>La identificación del Titular.</li>
                  <li>
                    Los datos de contacto (dirección física y/o electrónica y teléfonos de
                    contacto).
                  </li>
                  <li>
                    Los documentos que acrediten la identidad del Titular, o la representación de su
                    representante.
                  </li>
                  <li>
                    La descripción clara y precisa de los datos personales respecto de los cuales el
                    Titular busca ejercer alguno de los derechos.
                  </li>
                  <li>La descripción de los hechos que dan lugar al reclamo.</li>
                  <li>Los documentos que se quiera hacer valer. </li>
                  <li>Firma, número de identificación y huella.</li>
                  <li>Radicación en original. </li>
                </ol>
              </li>
              <li>
                Si el reclamo resulta incompleto, STO requerirá al interesado dentro de los cinco
                (5) días siguientes a la recepción del reclamo para que subsane las fallas.
                Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante
                presente la información requerida, se entenderá que ha desistido del reclamo.
              </li>
              <li>
                Si el Área que recibe el reclamo no es competente para resolverlo, dará traslado a
                quien corresponda en un término máximo de dos (2) días hábiles e informará de la
                situación al interesado.
              </li>
              <li>
                Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda
                que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos
                (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido
              </li>
              <li>
                El término máximo para atender el reclamo será de quince (15) días hábiles contados
                a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender
                el reclamo dentro de dicho término, se informará al interesado los motivos de la
                demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá
                superar los ocho (8) días hábiles siguientes al vencimiento del primer término
              </li>
            </ol>
          </PoliticyParagraph>
        </PoliticySubcontainer>
        <PoliticySubtitle>6.3. Supresión de Datos</PoliticySubtitle>
        <PoliticyParagraph>
          El Titular tiene el derecho a solicitar a STO la supresión (eliminación) de sus datos
          personales y en especial cuando:
        </PoliticyParagraph>
        <PoliticyParagraph>
          <ol type="a">
            <li>
              Considere que los mismos no están siendo tratados conforme a los principios, deberes y
              obligaciones previstas en la Ley 1581 de 2012.
            </li>
            <li>
              Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron
              recolectados.
            </li>
            <li>
              Se haya superado el periodo necesario para el cumplimiento de los fines para los que
              fueron recolectados.
            </li>
          </ol>
        </PoliticyParagraph>
        <PoliticyParagraph>
          Esta supresión implica la eliminación total o parcial de la información personal de
          acuerdo con lo solicitado por el Titular en los registros, archivos, bases de datos o
          tratamientos realizados por STO No obstante, este derecho no es absoluto y el responsable
          puede negar el ejercicio del mismo cuando:
        </PoliticyParagraph>
        <PoliticyParagraph>
          <ol type="i">
            <li>
              La solicitud de supresión de la información no procederá cuando el Titular tenga un
              deber legal o contractual de permanecer en la base de datos.
            </li>
            <li>
              La eliminación de datos obstaculice actuaciones judiciales o administrativas
              vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la
              actualización de sanciones administrativas.
            </li>
            <li>
              Los datos sean necesarios para proteger los intereses jurídicamente tutelados del
              Titular, para realizar una acción en función del interés público, o para cumplir con
              una obligación legalmente adquirida por el Titular.
            </li>
          </ol>
        </PoliticyParagraph>
        <PoliticySubtitle>6.4. Revocatoria de la Autorización.</PoliticySubtitle>
        <PoliticyParagraph>
          El Titular de los datos personales puede revocar el consentimiento al Tratamiento de sus
          datos personales en cualquier momento, siempre y cuando no lo impida una disposición
          legal.
        </PoliticyParagraph>
      </PoliticySubcontainer>
      <PoliticySubtitle>7. Seguridad de la Información.</PoliticySubtitle>
      <PoliticyParagraph>
        En desarrollo del principio de seguridad, STO ha adoptado medidas técnicas, administrativas
        y humanas razonables para proteger la información de los Titulares e impedir adulteración,
        pérdida, consulta, uso o acceso no autorizado o fraudulento. El acceso a los datos
        personales está restringido a sus Titulares y STO no permitirá el acceso a esta información
        por parte de terceros, a excepción de un pedido expreso del Titular de los datos o personas
        legitimadas de conformidad con la normatividad nacional.
      </PoliticyParagraph>
      <PoliticySubtitle>8. Vigencia de la Política.</PoliticySubtitle>
      <PoliticyParagraph>
        STO ha dado a conocer su Política de tratamiento el 23 de agosto de 2017. Cualquier cambio
        sustancial en esta política será dado a conocer a los titulares mediante el envío de
        comunicación a os datos de contacto autorizados. El término de las autorizaciones sobre el
        uso de los datos personales se entiende por el término de la relación comercial o de la
        vinculación al servicio y durante el ejercicio del objeto social de la compañía, salvo
        aquellos casos en los que la ley disponga un término distinto.
      </PoliticyParagraph>
    </PoliticyContainer>
  </Layout>
));

export default PoliticyPage;
